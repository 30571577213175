
            @import 'src/styles/mixins.scss';
        
.mainSection {
    margin: 70px auto 0 auto;
    text-align: center;
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;

        background-color: #002a3a;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: -10;
    }
    h2 {
        color: white;
        font-size: 24px;
        font-weight: bold;

        @include lg {
            font-size: 30px;
            line-height: 36px;
        }
    }
    p {
        color: white;
        margin-top: 30px;

        font-size: 16px;
        @include lg {
            font-size: 20px;
        }
    }
}

.btnStyle {
    margin: 30px auto 0 auto;
    padding: 16px;
    color: white;
    font-size: 18px;
    line-height: 27px;
    background-color: #f57f26;
    border-radius: 8px;
    font-weight: bold;
    max-width: 350px;

    cursor: pointer;
    transition: all 0.3s;
    @include lg {
        padding: 16px;
    }
}
.btnStyle:hover {
    box-shadow: 1px 1px 3px 2px rgba(59, 59, 59, 0.1);
    transform: translateY(-7px);
    transition: all 0.3s;
}
